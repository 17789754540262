import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    organizations: [],
  },
  getters: {
    organizations (state) { return state.organizations },
  },
  mutations: {
    setOrganizations (state, payload) { state.organizations = payload },
  },
  actions: {
    setOrganizations ({ commit }, payload) {
      commit('setOrganizations', payload)
    },
  },
})
