<template>
  <v-app>
    <template v-if="$route.path==='/login'">
      <router-view></router-view>
    </template>
    <template v-else>
      <v-app-bar app color="primary" dark flat>
        <div @click="$router.push('/')" class="d-flex align-center" style="cursor: pointer">
          <span class="headline">BizBrain Messaging</span>
        </div>
        <v-spacer></v-spacer>
        <div class="title mx-2">Administrator</div>
        <v-tooltip nudge-bottom="50" nudge-right="150">
          <template v-slot:activator="{ on }">
            <v-btn @click.prevent="logout" class="error--text" icon v-on="on">
              <v-icon>mdi-power</v-icon>
            </v-btn>
          </template>
          <span>Logout</span>
        </v-tooltip>
      </v-app-bar>
      <v-main>
        <router-view></router-view>
      </v-main>
    </template>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data () {return {}},
  methods: {
    async logout () {
      localStorage.clear()
      this.$router.push('/login').catch(() => {})
    },
  },
}
</script>
